/**
 * @generated SignedSource<<365aca171ec8cdfe9ab8f91926485cb5>>
 * @relayHash fd0a7f86bdeeecbf0ff5f4ce1fbd3af3
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-admin-accounts/504.0.0-2025-04-02T17:02:50.045Z/sellerResetPasswordMutation

import { ConcreteRequest } from 'relay-runtime';
export type SellerResetPasswordErrorCode = "INVALID_TOKEN" | "%future added value";
export type SellerResetPasswordInput = {
  clientMutationId?: string | null;
  password: string;
  passwordToken: string;
};
export type sellerResetPasswordMutation$variables = {
  input: SellerResetPasswordInput;
};
export type sellerResetPasswordMutation$data = {
  readonly sellerResetPassword: {
    readonly errorCode: SellerResetPasswordErrorCode | null;
  } | null;
};
export type sellerResetPasswordMutation = {
  response: sellerResetPasswordMutation$data;
  variables: sellerResetPasswordMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SellerResetPasswordPayload",
    "kind": "LinkedField",
    "name": "sellerResetPassword",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errorCode",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "sellerResetPasswordMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "sellerResetPasswordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "app-admin-accounts/504.0.0-2025-04-02T17:02:50.045Z/sellerResetPasswordMutation",
    "metadata": {},
    "name": "sellerResetPasswordMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "a2231630cafd244808e67b6b08f35ad6";

export default node;
