/**
 * @generated SignedSource<<d4fb02c3247e65dac34456825ba94725>>
 * @relayHash e048bbeadd81ae8a9c1ce47824169c7e
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-admin-accounts/504.0.0-2025-04-02T17:02:50.045Z/sellerLoginMutation

import { ConcreteRequest } from 'relay-runtime';
export type SupportedMFATypes = "CODE" | "%future added value";
export type SellerLoginInput = {
  captchaKey?: string | null;
  captchaToken?: string | null;
  clientMutationId?: string | null;
  cookieDomain?: string | null;
  email: string;
  mfaVerificationCode?: string | null;
  oauth?: SellerOauthParams | null;
  password: string;
  supportedMFATypes?: ReadonlyArray<SupportedMFATypes | null> | null;
};
export type SellerOauthParams = {
  clientId: string;
  redirectUri: string;
  scope: string;
  state: string;
};
export type sellerLoginMutation$variables = {
  input: SellerLoginInput;
};
export type sellerLoginMutation$data = {
  readonly sellerLogin: {
    readonly redirectUri: string | null;
  } | null;
};
export type sellerLoginMutation = {
  response: sellerLoginMutation$data;
  variables: sellerLoginMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SellerLoginPayload",
    "kind": "LinkedField",
    "name": "sellerLogin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "redirectUri",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "sellerLoginMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "sellerLoginMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "app-admin-accounts/504.0.0-2025-04-02T17:02:50.045Z/sellerLoginMutation",
    "metadata": {},
    "name": "sellerLoginMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "5a7fb30a54824188cdc90ec65458e1d6";

export default node;
